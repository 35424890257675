import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Header from '../components/Header/Header';
import SearchFilters from '../components/OffPlanSearchFilters/SearchFilters';
import FilterBlock from '../components/OffPlanSearchFilters/FilterBottom';
import Propertysale from '../components/PropertySale/OffplanSale'
import AboutResult from "../components/AboutResult/AboutResult"
import Paginations from '../components/Pagination/Pagination';
import Banner from "../components/BannerComp/Banner"
import OffPlanContact from "../components/Contact/OffPlanContact"
import FeatureOffPlan from "../components/FeatureOffPlan/FeatureOffPlan"
import Footer from '../components/Footer/Footer';
import { orderBy } from 'lodash';
import "../pages/SearchResults/SearchResult.scss";

import moment from 'moment';
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery"

const OffPlans = (props) => {
  const GQLPage = props?.GQLPage
  const [developerval, setDeveloperVal] = useState(props.developerval ? props.developerval : '');
  const [propertyval, setPropertyVal] = useState(props.propertyval ? props.propertyval : '');
  const [submitfilter, setSubmitfilter] = useState(props.submitfilter ? props.submitfilter : '');
  const [minpriceVal, setMinPriceVal] = useState(props.minpriceVal ? props.minpriceVal : '');
  const [maxpriceVal, setMaxPriceVal] = useState(props.maxpriceVal ? props.maxpriceVal : '');
  const [bedVal, setBedVal] = useState(props.bedVal ? props.bedVal : '');
  const [minbedVal, setMinBedVal] = useState(props.minbedVal ? props.minbedVal : '');
  const [maxbedVal, setMaxBedeVal] = useState(props.maxbedVal ? props.maxbedVal : '');
  const [currentmy, setCurrenty] = useState(moment().format('YYYY-MM-DD'));
  const [completionval, setCompletionVal] = useState(props.completionval ? props.completionval : '');
  const [areasArr, setAreasArr] = useState(props.areasArr ? props.areasArr : []);
  const [priceChange, setpriceChange] = useState(false)
  const [sort, setSort] = useState('');
  const [areaVal, setAreaVal] = useState(props?.area ? props?.area : '');
  const data = useStaticQuery(graphql`
query  {  
  glstrapi {
    offplans(publicationState: LIVE) {
        URL
        id
        name
        location
        developement_type
        price
        bedroom
        property_type
        completion_date
        description
        developer
        images
        imagetransforms
        property_category
        createdAt
        coming_soon
        call
        whatsapp
        Banner_Img {
          url
          url_sharp {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                width: 440
                height: 330
                quality: 80
                layout: FIXED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              )
            }
          }
        }
    }
  }
}
`)
  var properties = data.glstrapi.offplans;
  var exclusiveProperties = properties && properties.filter((exprop) => exprop.property_category == "exclusive_properties");
  var ourTopPickProperties = properties && properties.filter((exprop) => exprop.property_category == "our_top_pick");
  ourTopPickProperties = orderBy(ourTopPickProperties, ['createdAt'], ['desc']);
  exclusiveProperties = orderBy(exclusiveProperties, ['createdAt'], ['desc']);

  const OffPlanAreaListRaw = properties?.map((item, index) => ({
    "level": 1,
    "name": `${item.location}`,
    "slug": `${item.location.replace(/,/g, '').replace(/\s+/g, '-')}`
  }));

  const uniqueValuesSet = new Set();
  const OffPlanAreaList = OffPlanAreaListRaw.filter((obj) => {
    if (!uniqueValuesSet.has(obj.slug)) {
      uniqueValuesSet.add(obj.slug);
      return true;
    }
    return false;
  });

  const [OffPlanArr, setOffPlanArr] = useState(OffPlanAreaList ? OffPlanAreaList : []);

  properties.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  if (submitfilter == "true" || props.submitfilter == "true") {
    if (areasArr && areasArr.length > 0) {
      properties = properties.filter(item => areasArr.includes(item.location));
      properties = properties.filter(item => areasArr.some(keyword => item.location.toLowerCase().includes(keyword.toLowerCase())));
    } else if (areaVal !== "") {
      properties = properties.filter(item => item.location.toLowerCase().includes(areaVal.toLowerCase()));
    }
    if (developerval) {
      properties = properties.filter(item => item.developer.toLowerCase().includes(developerval.toLowerCase()))
    }
    // if(propertyval) {
    //   properties = properties.filter(item => item.property_type == propertyval)
    // }
    if (propertyval) {
      properties = properties.filter(item => (item.developement_type.toLowerCase().includes(propertyval.toLowerCase())) || (item.property_type == propertyval))
    }
    if (minpriceVal && minpriceVal > 0) {
      properties = properties.filter(item => item.price > minpriceVal)
    }
    if (maxpriceVal && maxpriceVal > 0) {
      properties = properties.filter(item => item.price < maxpriceVal)
    }
    if (minbedVal && minbedVal > 0) {
      properties = properties.filter(item => item.bedroom >= minbedVal)
    }
    if (maxbedVal && maxbedVal > 0) {
      properties = properties.filter(item => item.bedroom <= maxbedVal)
    }
    // if(bedVal && bedVal > 0) {
    //   properties = properties.filter(item => item.bedroom > bedVal)
    // }
    if (completionval) {
      if (completionval == "ready") {
        properties = properties.filter(item => moment(item.completion_date).format('YYYY-MM-DD') <= currentmy)
      } else {
        properties = properties.filter(item => moment(item.completion_date).format('YYYY') == completionval)
      }
    }

  }
  if (sort == "Highest Price") {
    properties.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
  } else if (sort == "Lowest Price") {
    properties.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  } else {
    properties.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }
  useEffect(() => {

    $(document).mouseup(function (e) {
      var container = $(".price-filter-dropdown")
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".price-filter-dropdown").removeClass("active")
      }
    });
  }, []);

  useEffect(() => {
    console.log(priceChange)
  }, [priceChange])
  //console.log("currentmycurrentmy", currentmy)
  return (
    <>
      <Header />
      <Banner
        title={GQLPage.Banner_Title}
        search={false}
        buttons={false}
        reviews={false}
        overlaybanner={GQLPage.Banner_Image}
        // bannerimage={areaguide.Banner_Image}
        imagetransforms={GQLPage.Banner_Image}
        id={GQLPage.id}
        // video={areaguide.Banner_Video_Link}
        content={GQLPage.Banner_Content}
        pageTitle={GQLPage.Title}
        type="off-plan"
        additionalClass="off-plan"

      /> 
      <SearchFilters OffPlanAreaList={OffPlanArr} submitfilter={setSubmitfilter} developerval={setDeveloperVal} propertyval={setPropertyVal} minpriceVal={setMinPriceVal} maxpriceVal={setMaxPriceVal} minbedVal={setMinBedVal} maxbedVal={setMaxBedeVal} bedVal={setBedVal} completionval={setCompletionVal} areasArr={setAreasArr} developerval1={developerval} propertyval1={propertyval} minpriceVal1={minpriceVal} maxpriceVal1={maxpriceVal} minbedVal1={minbedVal} maxbedVal1={maxbedVal} bedVal1={bedVal} completionval1={completionval} areasArr1={areasArr} setAreaVal1={setAreaVal} defaultArea={areaVal} />
      {exclusiveProperties && exclusiveProperties.length > 0 &&
        <FeatureOffPlan offPlanList={exclusiveProperties} title="Exclusive Properties" />
      }
      {ourTopPickProperties && ourTopPickProperties.length > 0 &&
        <FeatureOffPlan offPlanList={ourTopPickProperties} title="Trending Projects" />
      }
      <div className="offplan-filter">
        <Propertysale count={properties?.length} sort={setSort} setpriceChange={setpriceChange} />
      </div> 
      <div className='off-plan-wrapper'>
        {properties && properties.length > 0 ?
          <AboutResult properties={properties} developerval={developerval} propertyval={propertyval} minpriceVal={minpriceVal} maxpriceVal={maxpriceVal} minbedVal={minbedVal} maxbedVal={maxbedVal} bedVal={bedVal} completionval={completionval} areasArr={areasArr} />
          :
          <Container>
            <Row>
              <div className="no-properties-text off-plan-no">
                <p className="mb-20">Unfortunately, we do not currently have any properties that match your search criteria. Please try searching again with a different criteria.</p>
              </div>
            </Row>
          </Container>
        }
        <>
          {props.GQLModules && props.GQLModules.map((module, i) => {
            return (
              <>
                {module.__typename === "GLSTRAPI_ComponentComponentsSidebarContentWithSidebarForm" &&
                  <OffPlanContact module={module} type={module.__typename} />
                }
              </>
            )
          })}
        </>
      </div>
      {/*<Paginations/>*/}
      <Footer popularsearch={GQLPage.Select_Popular_Search} />
    </>
  )
}

export default OffPlans