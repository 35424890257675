import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useWindowSize } from "../../hooks/window-size"
import parse from "html-react-parser"
import { uniq } from "lodash"
import Select from 'react-select'
import "./FeatureOffPlan.scss"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeatureCard from "../OffPlanCard/FeatureCard"
import { graphql, Link, useStaticQuery } from "gatsby"

const FeatureOffPlan = ({ title, offPlanList }) => {
  const [windowWidth] = useWindowSize()

  const [list, setList] = useState(offPlanList)
  const [selectType, setSelectType] = useState(null)
  let types = uniq(offPlanList.map(a => a.property_type));
  const NextArrow = ({ onClick }) => {
    return (
      <button className="slick-next" onClick={onClick}>
        <i className="icon-pagination-right"></i>
      </button>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="slick-prev" onClick={onClick}>
        <i className="icon-pagination-left"></i>
      </button>
    );
  };
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const selectCategory = (type) => {
    setSelectType(type)
    let filterOffPlan = offPlanList.filter((a) => a.property_type == type)
    setList(filterOffPlan);

  };

  return (
    <section className={`select-reasons feature-offplan ${offPlanList[0].property_category}`}>
      <div className="select-reasons-wrap">
        <Container>
          <div className="intro">
            <div className="intro-content">
              <h2 className="heading">{title}</h2>
            </div>
            <div className="property_types d-none d-lg-flex">
              {types && types.length > 1 && types.map((type) => {
                return (<button className={`btn ${type == selectType ? 'active' : ''}`} onClick={() => { selectCategory(type) }}>
                  {type}
                </button>)
              })
              }
            </div>
            <div className="property_types d-block d-lg-none">
              {types && types.length > 1 &&
                <Select
                  options={types.map(type => ({ value: type, label: type }))}  
                  placeholder={"Select Property Type"} 
                  onChange={(e) => { selectCategory(e.value) }}
                  isSearchable={false}
                  className="react-select"
                  classNamePrefix="react-select"
                >
                </Select>

              }
              {/* <select name="property_type" className="form-select" onChange={(e) => { selectCategory(e.target.value) }}>
                  {types && types.length > 1 && types.map((type) => {
                    return (<option value={type} >
                      {type}
                    </option>)
                  })}
                </select> */}
            </div>

          </div>

          <Row className="areaguide-cards">
            <Slider className="areaguide-slider custom-arrow-slider" {...settings}>
              {list.map(item => (
                <Col className="small-card">
                  <FeatureCard key={item.id} card={item} />
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </section>
  )
}
export default FeatureOffPlan
