import React from "react"
import { Link } from "gatsby"
import "./ReasonCard.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { Offlinedate } from "../common/site/functions"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import { Row } from "react-bootstrap"
import parse from "html-react-parser"
import { CurrConvertPrice } from '../currency-converter/CurrConvertPrice'

const FeatureCard = props => {
  const ReasonItem = props.card

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let processedImages = JSON.stringify({})
  if (ReasonItem?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = ReasonItem.imagetransforms.Tile_Image_Transforms
  }

  return (
    <React.Fragment>
      <div className="news-card feature_card">
        <div className="properties-wrapper zoom">
          {/* <Link to="#" className="d-inline-block zoom"> */}
          {ReasonItem.coming_soon &&
            <button className="btn coming_soon">Coming Soon</button>
          }
          {ReasonItem.Banner_Img && (
            <Link
              className="zoom"
              to={`/off-plan-properties-in-dubai/${ReasonItem.URL}/`}
            >
              <GetGatsbyImage
                image={ReasonItem.Banner_Img}
                fallbackalt={ReasonItem.name}
              />
            </Link>
          )}
          {/* </Link> */}
        </div>
        <div className="about-properties">
          <div className="properties-info">
            <div className="properties-heading">
              <Link to={`/off-plan-properties-in-dubai/${ReasonItem.URL}/`}>
                <h2>
                  From {CurrConvertPrice(ReasonItem.price.toLocaleString())}
                </h2>
              </Link>

              <p>{ReasonItem.location}</p>
              <p className="completion"><strong>Handover:</strong> {Offlinedate(ReasonItem.completion_date)}</p>

            </div>
          </div>
          {(ReasonItem.call || ReasonItem.whatsapp) &&
            <div className="properties-contact">
              {ReasonItem.call &&
                <a href={`tel:${ReasonItem.call}`} className="btn call">
                  <i className="icon-call-white"></i> Call
                </a>
              }
              {ReasonItem.whatsapp &&
                <a href={`https://wa.me/${ReasonItem.whatsapp}`} className=" btn whatsapp">
                  <i className="icon-whatsapp-white"></i> WhatsApp
                </a>
              }

            </div>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default FeatureCard
